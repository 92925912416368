import { Flowbite, useThemeMode } from 'flowbite-react'
import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import CookieBanner from 'components/CookieBanner/CookieBanner'
import Footer from 'components/Footer'
import NavBar from 'components/NavBar'

const Root = () => {
  const theme = useThemeMode()

  useEffect(() => {
    theme.setMode('dark')
  }, [theme])

  return (
    <Flowbite>
      <div className="min-h-screen">
        <NavBar />
        <Outlet />
        <CookieBanner />
        <Footer />
      </div>
    </Flowbite>
  )
}

export default Root
