import { Button, Label, Select, Textarea } from 'flowbite-react'
import { showToast } from 'utils/toast'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import capitalize from 'lodash/capitalize'
import supabase from 'utils/supabase/client'

const subjects = ['Bug', 'Improvement', 'Report', 'Other']

type Inputs = {
  message: string
  subject: string
}

const Contact = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)

  const type = searchParams.get('type')
  const postId = searchParams.get('postId')

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      message: postId
        ? `I'd like to report the post with ID: ${postId}. Reason: `
        : '',
    },
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const {
      data: { user },
    } = await supabase().auth.getUser()

    const { error } = await supabase()
      .from('contact')
      .insert({
        message: data.message,
        subject: data.subject,
        user_id: user?.id ?? null,
      })

    if (error) {
      showToast('Failed to send message.', 'error')

      // TODO Handle
    } else {
      setHasSubmitted(true)
    }
  }

  if (hasSubmitted) {
    return (
      <div className="flex flex-col items-center justify-center mt-20">
        <h1 className="mb-6 text-4xl font-bold text-center">Thank you!</h1>
        <Button
          onClick={() => {
            navigate('/')
          }}
          className="mt-5"
          color="gray"
        >
          Home
        </Button>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center mt-20">
      <h1 className="mb-6 text-4xl font-bold text-center">Contact us</h1>

      <form
        className="flex flex-col min-w-full sm:min-w-96 px-4 md:px-0 gap-4 mt-10"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div className="mb-2 block">
            <Label htmlFor="subjects" value="Subject" />
          </div>

          <Select
            defaultValue={type ?? 'Other'}
            id="subjects"
            required
            {...register('subject', { required: true })}
          >
            {subjects.map((subject) => (
              <option key={subject}>{capitalize(subject)}</option>
            ))}
          </Select>
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="message" value="Your message" />
          </div>
          <Textarea
            id="message"
            placeholder="Leave a message..."
            required
            rows={8}
            {...register('message', { required: true })}
          />
        </div>

        <Button color="purple" type="submit">
          Send
        </Button>
      </form>
    </div>
  )
}

export default Contact
