import { Button } from 'flowbite-react'
import { Dropdown, Navbar } from 'flowbite-react'
import { HiMagnifyingGlass, HiUser } from 'react-icons/hi2'
import { HiOutlinePlus } from 'react-icons/hi'
import { logout, getUser } from 'utils/auth'
import { showToast } from 'utils/toast'
import { useLocation, useNavigate } from 'react-router-dom'
import TransparentButton from 'components/TransparentButton'

const NavBar = () => {
  const user = getUser()

  const navigate = useNavigate()
  const location = useLocation()

  const isOnNewPostPage = location.pathname === '/new'

  const onLogout = () => {
    void logout(navigate)
    showToast('Signed out', 'success')
  }

  return (
    <div className="bg-gray-800">
      <Navbar className="flex mx-auto w-full max-w-7xl px-2 sm:px-6 md:px-4 lg:px-8 h-[3.6rem]">
        <Navbar.Brand href={import.meta.env.VITE_BASE_URL}>
          <span className="whitespace-nowrap text-xl font-semibold dark:text-white">
            And Shame
          </span>
        </Navbar.Brand>

        <div className="flex justify-center">
          {user?.app_metadata.is_admin && (
            <Button
              className="mr-4"
              color="success"
              onClick={() => navigate('/review')}
            >
              <HiMagnifyingGlass className="size-4" />
            </Button>
          )}

          {!isOnNewPostPage && (
            <Button
              className="mr-6 font-medium"
              color="purple"
              onClick={() => navigate('/new')}
              size="sm"
              type="submit"
            >
              <HiOutlinePlus className="size-5 mr-2" />
              New Post
            </Button>
          )}

          {user ? (
            <Dropdown
              arrowIcon={false}
              inline
              label={<HiUser className="size-6 rounded-full bg-gray-700" />}
            >
              <Dropdown.Header>
                <span className="block text-sm">Signed in as</span>
                <span className="block truncate text-sm font-medium">
                  {user.email}
                </span>
              </Dropdown.Header>

              <Dropdown.Item onClick={onLogout}>Sign out</Dropdown.Item>
            </Dropdown>
          ) : (
            <TransparentButton onClick={() => navigate('sign-in')}>
              Sign in
            </TransparentButton>
          )}
        </div>
      </Navbar>
    </div>
  )
}

export default NavBar
