import { GeoLocation } from 'types/GeoLocation.types'
import { getGeoLocation } from 'routes/NewPost/components/DropArea/utils'
import { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import isEmpty from 'lodash/isEmpty'

import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './styles'

const DropArea = ({
  onFileSelected,
  onError,
}: {
  onFileSelected: (
    image: ArrayBuffer,
    geoLocation: GeoLocation,
    mimeType: string
  ) => void
  onError: (message: string | string[]) => void
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        onError(['File type not supported.', 'Please use JPEG or HEIC photos.'])
        return
      }

      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        if (!reader.result) {
          console.error('Failed to read image')
          return
        }

        // Extract exif data.

        const { geoLocation, errorMessage } = getGeoLocation(
          reader.result as ArrayBuffer
        )

        if (errorMessage) {
          onError(errorMessage)
        } else {
          onFileSelected(
            reader.result as ArrayBuffer,
            geoLocation!,
            acceptedFiles[0].type
          )
        }
      }

      reader.readAsArrayBuffer(acceptedFiles[0])
    },
    [onError, onFileSelected]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/heic': ['.heic', '.heics'],
        'image/heif': ['.avci', '.avcs', '.heif', '.heifs', '.HIF'],
      },
      onDrop,
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <div className="container w-96">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="text-center">
          {isDragReject ? (
            <span>
              Unsupported format.
              <br />
              Use JPEG or HEIC photos.
            </span>
          ) : (
            <>
              Drag and drop a photo here,
              <br />
              or click to select file.
            </>
          )}
        </p>
      </div>
    </div>
  )
}

export default DropArea
