import ExifReader from 'exifreader'
import { GeoLocation } from 'types/GeoLocation.types'

/**
 * Extracts longitude and latitude from a photo.
 */
export const getGeoLocation = (
  image: ArrayBuffer
):
  | {
      geoLocation: GeoLocation
      errorMessage: null
    }
  | {
      geoLocation: null
      errorMessage: string | string[]
    } => {
  const tags = ExifReader.load(image)

  if (
    !tags.GPSLatitude ||
    !tags.GPSLatitudeRef ||
    !tags.GPSLongitude ||
    !tags.GPSLongitudeRef
  ) {
    return {
      errorMessage: [
        "Could not find GPS coordinates in the photo's exif data.",
        'Make sure to add a photo you have taken yourself.',
      ],
      geoLocation: null,
    }
  }

  const latitude = tags.GPSLatitude.description
  const longitude = tags.GPSLongitude.description

  const isWest = tags.GPSLongitudeRef.description === 'West longitude'
  const signedLongitude = `${isWest ? '-' : ''}${longitude}`

  const isSouth = tags.GPSLatitudeRef.description === 'South latitude'
  const signedLatitude = `${isSouth ? '-' : ''}${latitude}`

  return {
    errorMessage: null,
    geoLocation: {
      latitude: signedLatitude,
      longitude: signedLongitude,
    },
  }
}
