import { Alert, Button, Label, TextInput } from 'flowbite-react'
import { ErrorMessage } from '@hookform/error-message'
import { HiExclamationCircle } from 'react-icons/hi2'
import { Link } from 'react-router-dom'
import { signUp } from 'utils/auth'
import { Turnstile } from '@marsidev/react-turnstile'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useState } from 'react'

type Inputs = {
  confirmPassword: string
  email: string
  password: string
}

const SignUpForm = ({
  onLoading,
  onSignedUp,
}: {
  onLoading: () => void
  onSignedUp: () => void
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<Inputs>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [turnstileToken, setTurnstileToken] = useState<string>('')

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    onLoading()

    const errorMessage = await signUp(data.email, data.password, turnstileToken)

    if (errorMessage) {
      setErrorMessage(errorMessage)
    } else {
      onSignedUp()
    }
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white-500">
          Create an account
        </h2>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex max-w-md flex-col gap-4"
          >
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                {...register('email', { required: true })}
                autoComplete="email"
                helperText={<ErrorMessage errors={errors} name="email" />}
                id="email"
                required
                type="email"
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label
                  color={errors.password ? 'failure' : ''}
                  htmlFor="password"
                  value="Password"
                />
              </div>
              <TextInput
                {...register('password', {
                  required: true,
                  minLength: {
                    value: 6,
                    message: 'At least six characters',
                  },
                })}
                autoComplete="off"
                helperText={
                  <ErrorMessage
                    as={Label}
                    color="failure"
                    errors={errors}
                    name="password"
                  />
                }
                id="password"
                required
                type="password"
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label
                  color={errors.confirmPassword && 'failure'}
                  htmlFor="confirm-password"
                  value="Confirm password"
                />
              </div>
              <TextInput
                {...register('confirmPassword', {
                  required: true,
                  validate: (value) =>
                    value === watch('password') || 'Passwords do not match',
                })}
                autoComplete="off"
                helperText={
                  <ErrorMessage
                    as={Label}
                    color="failure"
                    errors={errors}
                    name="confirmPassword"
                  />
                }
                id="confirm-password"
                required
                type="password"
              />
            </div>

            <div className="flex justify-center">
              {errorMessage && (
                <Alert color="failure" icon={HiExclamationCircle}>
                  {errorMessage}
                </Alert>
              )}
            </div>

            <div className="flex justify-center mt-2">
              <Turnstile
                siteKey={import.meta.env.VITE_TURNSTILE_SITEKEY}
                onSuccess={(token) => {
                  setTurnstileToken(token)
                }}
                onError={() => {
                  // TODO Handle
                  console.error('Turnstile error!')
                }}
              />
            </div>

            <Button className="mt-2" color="purple" type="submit">
              Sign up
            </Button>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?{' '}
            <Link
              to={'/sign-in'}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default SignUpForm
