import { Button } from 'flowbite-react'
import { getCroppedImg } from 'routes/NewPost/components/ImageCropper/utils'
import { useState } from 'react'
import Cropper from 'react-easy-crop'
import type { Point, Area } from 'react-easy-crop'

const ImageCropper = ({
  imageSrc,
  onCropDone,
  onError,
}: {
  imageSrc: string
  onCropDone: (croppedImage: Blob) => void
  onError: (message: string | string[]) => void
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)

  const onDone = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels!)

      if (croppedImage) {
        onCropDone(croppedImage)
      } else {
        onError('Failed to crop image.')
      }
    } catch (error) {
      onError('Failed to crop image.')
    }
  }

  const onCropComplete = (_: Area, newCroppedAreaPixels: Area) => {
    setCroppedAreaPixels(newCroppedAreaPixels)
  }

  return (
    <div className="flex flex-col w-full">
      <h2 className="text-center mb-4">Crop</h2>
      <div className="relative h-[30rem]">
        <Cropper
          image={imageSrc}
          crop={crop}
          aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="flex justify-center mt-4">
        <Button className="w-32" onClick={onDone} color="purple">
          Ok
        </Button>
      </div>
    </div>
  )
}

export default ImageCropper
