import { Alert, Button, Label, TextInput } from 'flowbite-react'
import { HiInformationCircle } from 'react-icons/hi'
import { LockClosedIcon } from '@heroicons/react/16/solid'
import { updatePassword } from 'utils/auth'
import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FullscreenSpinner from 'components/FullscreenSpinner'
import supabase from 'utils/supabase/client'

type Inputs = {
  password: string
}

const ResetPassword = () => {
  const { register, handleSubmit } = useForm<Inputs>()
  const navigate = useNavigate()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPasswordUpdated, setIsPasswordUpdated] = useState<boolean>(false)

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)

      const {
        data: { user },
      } = await supabase().auth.getUser()

      setIsAuthenticated(!!user)
      setIsLoading(false)
    }

    void getData()
  }, [])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await updatePassword(data.password)

    setIsPasswordUpdated(true)
  }

  if (isLoading) {
    return <FullscreenSpinner />
  } else if (!isAuthenticated) {
    return (
      <div className="flex flex-col mt-10">
        <div className="flex justify-center">
          <Alert className="w-fit" color="info" icon={HiInformationCircle}>
            You need to be signed update your password.
          </Alert>
        </div>
      </div>
    )
  } else if (isPasswordUpdated) {
    return (
      <div className="flex flex-col mt-10 items-center">
        <div className="flex flex-row items-center">
          <LockClosedIcon className="mr-2 size-6" />
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-white-500">
            Password updated!
          </h2>
        </div>

        <Button color="purple" className="mt-10" onClick={() => navigate('/')}>
          Home
        </Button>
      </div>
    )
  }

  return (
    <>
      <h1 className="my-10 mb-6 text-4xl font-bold text-center">
        Update password
      </h1>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex max-w-md flex-col gap-4"
          >
            <div>
              <div className="mb-2 block">
                <Label htmlFor="password" value="New password" />
              </div>
              <TextInput
                {...register('password', { required: true })}
                autoComplete="off"
                id="password"
                required
                type="password"
              />
            </div>

            <Button className="mt-2" color="purple" type="submit">
              Update password
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
