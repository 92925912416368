import { Alert, Button, Label, Spinner, TextInput } from 'flowbite-react'
import { HiExclamationCircle } from 'react-icons/hi2'
import { Link } from 'react-router-dom'
import { showToast } from 'utils/toast'
import { signIn } from 'utils/auth'
import { Turnstile } from '@marsidev/react-turnstile'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useRef, useState } from 'react'
import type { TurnstileInstance } from '@marsidev/react-turnstile'

type Inputs = {
  email: string
  password: string
}

const SignInForm = ({ onSignedIn }: { onSignedIn: () => void }) => {
  const turnstileRef = useRef<TurnstileInstance>(null)

  const { register, handleSubmit } = useForm<Inputs>()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [turnstileToken, setTurnstileToken] = useState<string>('')
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsSigningIn(true)

    const errorMessage = await signIn(data.email, data.password, turnstileToken)

    setIsSigningIn(false)

    if (errorMessage) {
      setErrorMessage(errorMessage)
      turnstileRef.current?.reset()
    } else {
      showToast('Signed in!', 'success')

      onSignedIn()
    }
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white-500">
          Sign in to your account
        </h2>
      </div>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex max-w-md flex-col gap-4"
          >
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                {...register('email', { required: true })}
                autoComplete="email"
                id="email"
                required
                tabIndex={1}
                type="email"
              />
            </div>

            <div>
              <div className="mb-2 flex items-center">
                <Label htmlFor="password" value="Password" />
                <Link
                  to={'/reset-password'}
                  className="text-sm ml-auto font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </Link>
              </div>
              <TextInput
                {...register('password', { required: true })}
                autoComplete="off"
                id="password"
                required
                tabIndex={2}
                type="password"
              />
            </div>

            <div className="flex justify-center">
              {errorMessage && (
                <Alert color="failure" icon={HiExclamationCircle}>
                  {errorMessage}
                </Alert>
              )}
            </div>

            <div className="flex justify-center mt-2">
              <Turnstile
                onError={() => {
                  // TODO Handle
                  console.error('Turnstile error!')
                }}
                onSuccess={(token) => {
                  setTurnstileToken(token)
                }}
                ref={turnstileRef}
                siteKey={import.meta.env.VITE_TURNSTILE_SITEKEY}
              />
            </div>

            <Button
              disabled={isSigningIn}
              className="mt-2"
              color="purple"
              tabIndex={3}
              type="submit"
            >
              {isSigningIn ? (
                <div className="flex flex-row items-center">
                  <Spinner className="mr-3" size="sm" />
                  <p>Signing in...</p>
                </div>
              ) : (
                <p>Sign in</p>
              )}
            </Button>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <Link
              to={'/sign-up'}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign up now
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default SignInForm
