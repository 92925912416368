import { PostgrestError } from '@supabase/supabase-js'
import { VotesSummary } from 'types/VotesSummary.types'
import supabase from 'utils/supabase/client'

export const voteAgree = async (postId: number) => {
  const {
    data: { user },
  } = await supabase().auth.getUser()

  if (!user) {
    // TODO Handle
    console.error('Failed to vote, could not find user.')
    return
  }

  // TODO Why does user_id need to be sent along here? It isn't needed
  // in FileUpload.tsx when creating a row in the to_review table.

  const { error } = await supabase().from('votes').insert({
    image_id: postId,
    vote: 'agree',
    user_id: user.id,
  })

  if (error) {
    console.error('Error inserting agree vote:', error)
  }
}

export const voteDisagree = async (postId: number) => {
  const {
    data: { user },
  } = await supabase().auth.getUser()

  if (!user) {
    // TODO Handle
    console.error('Failed to vote, could not find user.')
    return
  }

  const { error } = await supabase().from('votes').insert({
    image_id: postId,
    vote: 'disagree',
    user_id: user.id,
  })

  if (error) {
    console.error('Error inserting disagree vote:', error)
  }
}

export const withdrawVote = async (postId: number) => {
  const { error } = await supabase()
    .from('votes')
    .delete()
    .eq('image_id', postId)

  if (error) {
    console.error('Error deleting vote:', error)
  }
}

export const getVotesSummary = async (
  postId: number
): Promise<
  | {
      error: null
      votesSummary: VotesSummary
    }
  | {
      error: string | PostgrestError
      votesSummary: null
    }
> => {
  const { data: votesSummary, error } = await supabase().rpc(
    'summarize_votes',
    {
      _id: postId,
    }
  )

  if (error) {
    return {
      error,
      votesSummary: null,
    }
  }

  return {
    error,
    votesSummary: votesSummary as VotesSummary,
  }
}

export const getUserCastVote = async (
  imageId: number,
  userId: string
): Promise<
  | {
      vote: string | null
      error: null
    }
  | {
      vote: null
      error: string | PostgrestError
    }
> => {
  const { data, error } = await supabase()
    .from('votes')
    .select()
    .eq('image_id', imageId)
    .eq('user_id', userId)
    .maybeSingle()

  if (error ?? !data) {
    return {
      vote: null,
      error,
    }
  }

  return {
    vote: data.vote,
    error,
  }
}
