import { Modal } from 'flowbite-react'
import SignInForm from 'components/SignInForm'

const SignInModal = ({
  onClose,
  onSignedIn,
  shouldShow,
}: {
  onClose: () => void
  onSignedIn: () => void
  shouldShow: boolean
}) => (
  <Modal dismissible show={shouldShow} size="md" onClose={onClose} popup>
    <Modal.Header />
    <Modal.Body>
      <div className="wrapper">
        <SignInForm onSignedIn={onSignedIn} />
      </div>
    </Modal.Body>
  </Modal>
)

export default SignInModal
