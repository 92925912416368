export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '100px 0',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#353535',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
} as const

export const focusedStyle = {
  borderColor: '#2196f3',
}

export const acceptStyle = {
  borderColor: '#00e676',
}

export const rejectStyle = {
  borderColor: '#ff1744',
}
