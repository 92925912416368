import { Button } from 'flowbite-react'
import { CheckIcon } from '@heroicons/react/16/solid'
import { useNavigate } from 'react-router-dom'

const UploadSuccesful = ({ onResetPage }: { onResetPage: () => void }) => {
  const navigate = useNavigate()
  const onGoHome = () => navigate('/')

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-center">
        <CheckIcon className="mr-2 size-12" />
        <h2 className="text-center text-2xl font-bold my-8">Submitted!</h2>
      </div>

      <p className="text-center mx-16">
        You will receive an email once your post has been reviewed.
      </p>

      <div className="flex space-x-2 mt-10">
        <Button color="dark" onClick={onGoHome}>
          Home
        </Button>
        <Button color="purple" onClick={onResetPage}>
          Add another
        </Button>
      </div>
    </div>
  )
}

export default UploadSuccesful
