import { createClient } from '@supabase/supabase-js'
import { Database } from 'types/database.types'

const client = createClient<Database>(
  import.meta.env.VITE_SUPABASE_URL as string,
  import.meta.env.VITE_SUPABASE_ANON_KEY as string
)

const supabase = () => client

export default supabase
