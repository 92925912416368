import { Bounce, toast, ToastPosition, TypeOptions } from 'react-toastify'

export const showToast = (message: string, type: TypeOptions) => {
  const options = {
    autoClose: 2000,
    closeOnClick: true,
    draggable: false,
    hideProgressBar: true,
    pauseOnHover: true,
    position: 'top-right' as ToastPosition,
    progress: undefined,
    theme: 'dark',
    transition: Bounce,
  }

  if (type === 'success') {
    toast.success(message, options)
  } else if (type === 'info') {
    toast.info(message, options)
  } else if (type === 'error') {
    toast.error(message, options)
  } else if (type === 'warning') {
    toast.warning(message, options)
  }
}
