import { ReactNode } from 'react'

const TransparentButton = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode
  className?: string
  onClick: () => void
}) => (
  <button
    className={`text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 flex items-center text-sm font-medium ${className ?? ''}`}
    onClick={onClick}
  >
    {children}
  </button>
)

export default TransparentButton
