import { POSTS_TO_REVIEW_BUCKET } from 'global/variables'
import heic2any from 'heic2any'
import * as tus from 'tus-js-client'
import supabase from 'utils/supabase/client'

/**
 * Converts a heic photo to jpeg.
 */
export const heicToJpeg = async (image: ArrayBuffer) => {
  const conversionResult = await heic2any({
    blob: new Blob([image]),
    toType: 'image/jpeg',
    quality: 0.6,
  })

  return Array.isArray(conversionResult)
    ? conversionResult[0]
    : conversionResult
}

/**
 * Converts an ArrayBuffer or Blob to a Base64 string.
 */
export const arrayBufferToBase64 = (
  input: Blob | ArrayBuffer
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    if (input instanceof Blob) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).split(',')[1]
          resolve(`data:${input.type};base64,${base64String}`)
        } else {
          reject(new Error('Failed to convert Blob to base64.'))
        }
      }
      reader.onerror = reject
      reader.readAsDataURL(input) // Read the Blob as a data URL
    } else if (input instanceof ArrayBuffer) {
      // Create a Uint8Array from the ArrayBuffer
      const bytes = new Uint8Array(input)

      // Convert the bytes into a string
      let binary = ''
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i])
      }

      // Convert the binary string to a base64 encoded string
      const base64String = window.btoa(binary)

      // Return the base64 string prefixed with the data URL format for images
      resolve(`data:image/jpeg;base64,${base64String}`)
    } else {
      reject(new Error('Input must be a Blob or an ArrayBuffer.'))
    }
  })
}

/**
 * Resizes an image.
 * @param imageUrl base64'd image
 * @param maxSize  Max width or height of new image
 * @returns Resized image in base64 format
 */
export const resizeImage = (
  imageUrl: string,
  maxSize: number
): Promise<string> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    image.addEventListener('load', () => {
      if (!context) return ''

      const maxWidth = maxSize
      const maxHeight = maxSize

      const imageWidth = image.width
      const imageHeight = image.height

      const scale = Math.min(maxWidth / imageWidth, maxHeight / imageHeight)

      const imageWidthScaled = imageWidth * scale
      const imageHeightScaled = imageHeight * scale

      canvas.width = imageWidthScaled
      canvas.height = imageHeightScaled

      context.drawImage(image, 0, 0, imageWidthScaled, imageHeightScaled)

      const newImageUrl = context.canvas.toDataURL('image/jpeg')

      resolve(newImageUrl)
    })
    image.addEventListener('error', (error) => {
      console.log('error', error)
      reject(error)
    })

    image.src = imageUrl
  })

/**
 * Uploads an image to Supabase's storage.
 */
export const uploadFile = async ({
  image,
  filename,
  onImageUploadError,
  onProgress,
  onSuccess,
}: {
  image: Blob
  filename: string
  onImageUploadError: (message: string, error?: Error) => void
  onProgress: (bytesUploaded: number, bytesTotal: number) => void
  onSuccess: () => void
}) => {
  const {
    data: { session },
  } = await supabase().auth.getSession()

  if (!session) {
    onImageUploadError("User isn't signed in.")

    return
  }

  const upload = new tus.Upload(image, {
    endpoint: `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/upload/resumable`,
    retryDelays: [0, 3000, 5000, 10000, 20000],
    headers: {
      authorization: `Bearer ${session.access_token}`,
    },
    uploadDataDuringCreation: true,
    // To allow re-uploading the same image
    removeFingerprintOnSuccess: true,
    metadata: {
      bucketName: POSTS_TO_REVIEW_BUCKET,
      objectName: filename,
      contentType: 'image/webp',
      cacheControl: '3600',
    },
    // NOTE: it must be set to 6MB (for now) do not change it.
    chunkSize: 6 * 1024 * 1024,
    onError: function (error) {
      onImageUploadError('Failed to upload image.', error)
    },
    onProgress,
    onSuccess,
  })

  //   Check if there are any previous uploads to continue.
  return upload.findPreviousUploads().then(function (previousUploads) {
    // Found previous uploads so we select the first one.
    if (previousUploads.length) {
      upload.resumeFromPreviousUpload(previousUploads[0])
    }

    // Start the upload
    upload.start()
  })
}

/**
 * Generates a thumbnail from an image.
 */
export const generateThumbnail = (
  image: Blob,
  thumbnailWidth = 500
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    const img = new Image()

    // Create a URL for the image blob and set it as the source of the image
    const url = URL.createObjectURL(image)
    img.src = url

    img.onload = () => {
      // Calculate the thumbnail height while maintaining the aspect ratio
      const aspectRatio = img.height / img.width
      const thumbnailHeight = thumbnailWidth * aspectRatio

      // Create a canvas element and set its size to the thumbnail dimensions
      const canvas = document.createElement('canvas')
      canvas.width = thumbnailWidth
      canvas.height = thumbnailHeight

      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject(new Error('Failed to get 2D context from canvas'))
        return
      }

      // Draw the image onto the canvas with the new dimensions
      ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight)

      // Convert the canvas to a Blob
      canvas.toBlob((thumbnailBlob) => {
        // Release the object URL
        URL.revokeObjectURL(url)
        if (thumbnailBlob) {
          resolve(thumbnailBlob)
        } else {
          reject(new Error('Failed to create Blob from canvas'))
        }
      }, image.type)
    }

    img.onerror = () => {
      URL.revokeObjectURL(url)
      reject(new Error('Failed to load image from Blob'))
    }
  })

export const addWatermark = async (image: Blob): Promise<Blob> => {
  return new Promise<Blob>((resolve, reject) => {
    const img = new Image()

    // Create a FileReader to read the image Blob
    const reader = new FileReader()
    reader.onload = () => {
      img.src = reader.result as string
    }
    reader.onerror = reject

    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject(new Error('Failed to get canvas context'))
        return
      }

      // Set canvas dimensions to match the image
      canvas.width = img.width
      canvas.height = img.height

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0)

      // Set watermark styles
      ctx.font = '48px Arial'
      ctx.fillStyle = 'rgba(255, 255, 255, 0.5)' // White with 50% opacity
      ctx.textAlign = 'right'
      ctx.textBaseline = 'bottom'

      // Calculate position for the watermark
      const xPos = canvas.width - 20 // 20px from the right edge
      const yPos = canvas.height - 20 // 20px from the bottom edge

      // Add the watermark text
      ctx.fillText('andshame.com', xPos, yPos)

      // Convert the canvas back to a Blob
      canvas.toBlob((watermarkedBlob) => {
        if (watermarkedBlob) {
          resolve(watermarkedBlob)
        } else {
          reject(new Error('Failed to create Blob from canvas'))
        }
      }, image.type)
    }

    img.onerror = reject

    // Start reading the image Blob
    reader.readAsDataURL(image)
  })
}
