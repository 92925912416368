import { useNavigate } from 'react-router-dom'
import SignInForm from 'components/SignInForm'

const SignIn = () => {
  const navigate = useNavigate()

  const onSignedIn = () => {
    navigate('/')
  }

  return <SignInForm onSignedIn={onSignedIn} />
}

export default SignIn
