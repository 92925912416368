import { Alert } from 'flowbite-react'
import { HiExclamationTriangle } from 'react-icons/hi2'

const ErrorMessage = ({ message }: { message: string }) => (
  <div className="flex justify-center mt-10">
    <Alert className="w-fit" color="warning" icon={HiExclamationTriangle}>
      {message}
    </Alert>
  </div>
)

export default ErrorMessage
