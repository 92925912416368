import { Alert, Button } from 'flowbite-react'
import { HiExclamationCircle } from 'react-icons/hi'
import castArray from 'lodash/castArray'

const FailedToAddPost = ({
  message,
  onTryAgain,
}: {
  message: string | string[]
  onTryAgain: () => void
}) => (
  <div className="flex flex-col items-center space-y-10 mt-10">
    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-white-500">
      Something went wrong
    </h2>

    <Alert color="failure" icon={HiExclamationCircle}>
      {castArray(message).map((messageChunk) => (
        <p key={messageChunk}>{messageChunk}</p>
      ))}
    </Alert>

    <Button color="purple" onClick={onTryAgain}>
      Try again
    </Button>
  </div>
)

export default FailedToAddPost
