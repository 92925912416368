/**
 * Adds '-thumbnail' to a filename.
 * E.g. addThumbnailPrefix('my-file.jpg')
 *   -> 'my-file-thumbnail.jpg'
 */
export const addThumbnailPrefix = (filename: string) => {
  const withoutExtension = filename.split('.').shift()
  const extension = filename.split('.').pop()

  return `${withoutExtension}-thumbnail.${extension}`
}
