import ReactMarkdown from 'react-markdown'
import privacyPolicy from 'assets/privacy-policy.md?raw'

const PrivacyPolicy = () => (
  <div className="flex flex-col items-center mt-10 mx-4">
    <article className="prose dark:prose-invert">
      <ReactMarkdown>{privacyPolicy}</ReactMarkdown>
    </article>
  </div>
)

export default PrivacyPolicy
