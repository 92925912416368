import { HiMail } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const Footer = () => (
  <div className="sticky top-[100vh] mt-16">
    <div className="lg:px-8 max-w-2xl mx-auto px-2 sm:px-6 w-full">
      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="flex items-center justify-center py-3">
        <Link to={'/contact?type=Other'} className="flex flex-row items-center">
          <HiMail className="mr-1 size-6 text-gray-500" />
          <p className="text-sm text-gray-500">Contact</p>
        </Link>
        <span className="mx-6 text-gray-500">|</span>
        <Link to={'/privacy-policy'}>
          <p className="text-sm text-gray-500">Privacy policy</p>
        </Link>
      </div>
    </div>
  </div>
)

export default Footer
