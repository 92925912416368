import { addThumbnailPrefix } from 'utils/addThumbnailPrefix'
import { Button, Spinner } from 'flowbite-react'
import { format } from 'date-fns'
import { HiXMark } from 'react-icons/hi2'
import { PostInView } from 'types/PostInView.types'
import { POSTS_BUCKET } from 'global/variables'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ModernDrawer from 'react-modern-drawer'
import supabase from 'utils/supabase/client'
import TransparentButton from 'components/TransparentButton'

const Drawer = ({
  isShowing,
  onClose,
  post,
}: {
  isShowing: boolean
  post?: PostInView
  onClose: () => void
}) => {
  const navigate = useNavigate()

  const [thumbnailURL, setThumbnailURL] = useState<string>('')
  const [previousPostId, setPreviousPostId] = useState<number>(-1)

  useEffect(() => {
    const getData = () => {
      if (!post) {
        return
      }

      if (post.id !== previousPostId) {
        setThumbnailURL('')
      }

      setPreviousPostId(post.id)

      const { data } = supabase()
        .storage.from(POSTS_BUCKET)
        .getPublicUrl(addThumbnailPrefix(post.filename))

      setThumbnailURL(data.publicUrl)
    }

    getData()
  }, [post, previousPostId])

  const gotoViewPost = () => {
    navigate(`post/${post?.id}`)
  }

  return (
    <ModernDrawer
      open={isShowing}
      onClose={onClose}
      direction="right"
      style={{ backgroundColor: '#242424' }}
      duration={300}
      enableOverlay={true}
    >
      <TransparentButton className="mt-4 ml-2" onClick={onClose}>
        <HiXMark className="size-5" />
      </TransparentButton>

      <div className="flex flex-col items-center">
        {!thumbnailURL && (
          <Spinner color="purple" className="my-20" size="lg" />
        )}

        {thumbnailURL && (
          <img
            alt="Image thumbnail"
            className="px-2 my-4 rounded-md"
            src={thumbnailURL}
          />
        )}

        {thumbnailURL && post && (
          <p className="text-center text-sm text-gray-500">
            {`Added ${format(new Date(post.inserted_at), 'MMM d, yyyy')}`}
          </p>
        )}

        {thumbnailURL && (
          <Button color="purple" className="mt-4 " onClick={gotoViewPost}>
            View
          </Button>
        )}
      </div>
    </ModernDrawer>
  )
}

export default Drawer
