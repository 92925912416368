import { format } from 'date-fns'
import { ImageSkeleton } from 'routes/ViewPost/components/ImageSkeleton'
import { Post } from 'types/Post.types'
import { POSTS_BUCKET } from 'global/variables'
import { showToast } from 'utils/toast'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import supabase from 'utils/supabase/client'
import TransparentButton from 'components/TransparentButton'
import VotingButtons from 'routes/ViewPost/components/VotingButtons/VotingButtons'

const ViewPost = () => {
  const navigate = useNavigate()
  const { postId } = useParams()

  const [imageURL, setImageURL] = useState<string>('')
  const [imageDoneLoading, setImageDoneLoading] = useState<boolean>(false)
  const [post, setPost] = useState<Post | null>(null)

  useEffect(() => {
    const getData = async () => {
      if (!postId) {
        throw Error('Missing post ID')
      }

      const { data: post } = await supabase()
        .from('posts')
        .select()
        .eq('id', postId)
        .single()

      if (!post) {
        showToast('Failed to get post.', 'error')

        // Todo report error

        return
      }

      setPost(post)

      const { data } = supabase()
        .storage.from(POSTS_BUCKET)
        .getPublicUrl(post.filename)

      if (data.publicUrl) {
        setImageURL(data.publicUrl)
      } else {
        showToast('Failed to get post.', 'error')

        // Todo report error
      }
    }

    void getData()
  }, [postId])

  const onImageLoaded = () => {
    setImageDoneLoading(true)
  }

  return (
    <div className="flex flex-col items-center w-full mx-auto max-w-7xl px-2 md:px-4 lg:px-8">
      <div className="flex flex-row justify-between mt-8 w-full">
        <TransparentButton
          className="text-gray-500"
          onClick={() => navigate(-1)}
        >{`<`}</TransparentButton>

        {post && (
          <TransparentButton
            className="text-gray-500"
            onClick={() => navigate(`/contact?type=Report&postId=${post?.id}`)}
          >
            Report
          </TransparentButton>
        )}
      </div>

      <div className="flex mt-2 mb-4 w-full">
        {!imageDoneLoading && <ImageSkeleton />}

        <img
          className={`rounded-md ${imageDoneLoading ? '' : 'w-0'} `}
          onLoad={onImageLoaded}
          src={imageURL}
          alt="Image"
        />
      </div>

      <div className="flex flex-col items-center justify-center px-4 lg:px-0 max-w-7xl w-full">
        <VotingButtons postId={post?.id} />

        {post && (
          <p className="mt-10 text-center text-sm text-gray-500">
            {`Added ${format(new Date(post.inserted_at), 'MMM d, yyyy')}`}
          </p>
        )}
      </div>
    </div>
  )
}

export default ViewPost
