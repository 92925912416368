import { BoundingBox } from 'types/BoundingBox.types'
import { Button, Spinner } from 'flowbite-react'
import {
  CircleMarker,
  MapContainer,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import { GeoLocationNumber } from 'types/GeoLocationNumber.types'
import { HiMapPin } from 'react-icons/hi2'
import { PostInView } from 'types/PostInView.types'
import { showToast } from 'utils/toast'
import { useCallback, useRef, useState } from 'react'
import L, { LatLng, Map } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import 'leaflet/dist/leaflet.css'

const purpleColor = '#6C2BD9'

// Workaround to leaflet not exporting MarkerCluster.
interface CustomMarkerCluster extends L.Marker {
  getChildCount: () => number
}

const createClusterCustomIcon = (cluster: CustomMarkerCluster) =>
  L.divIcon({
    html: `
      <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="16" fill="#6C2BD9" stroke="white" stroke-width="1.5"/>
        <text x="20" y="20" font-size="14" fill="white" text-anchor="middle" dominant-baseline="middle">${cluster.getChildCount()}</text>
      </svg>`,
    className: '',
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  })

const CustomMap = ({
  geolocation,
  onBoundingBoxChanged,
  onPreviewPost,
  posts,
}: {
  geolocation: GeoLocationNumber | null
  onBoundingBoxChanged: (latLng: LatLng, newBoundingBox: BoundingBox) => void
  onPreviewPost: (post: PostInView) => void
  posts: PostInView[]
}) => {
  const mapRef = useRef<Map | null>(null)

  const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>(false)

  const updateBoundingBox = () => {
    if (!mapRef.current) return

    const center = mapRef.current.getCenter()
    const latLngBounds = mapRef.current.getBounds()

    const { lat: max_lat, lng: max_long } = latLngBounds.getNorthEast()
    const { lat: min_lat, lng: min_long } = latLngBounds.getSouthWest()

    onBoundingBoxChanged(center, {
      min_lat,
      min_long,
      max_lat,
      max_long,
    })
  }

  const MapEvents = () => {
    useMapEvents({
      moveend: updateBoundingBox,
      zoomend: updateBoundingBox,
    })

    return null
  }

  const useMyLocation = () => {
    if (navigator.geolocation) {
      setIsLoadingLocation(true)

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords

          if (mapRef.current) {
            mapRef.current.setView([latitude, longitude])
          }

          setIsLoadingLocation(false)
        },
        (error) => {
          showToast('Failed to get user location.', 'error')

          // TODO report error
          console.error('Failed to get user location: ', error)

          setIsLoadingLocation(false)
        }
      )
    } else {
      console.log('GeoLocation is not supported by this browser')
    }
  }

  const handleRect = useCallback((map: Map) => {
    if (map) {
      mapRef.current = map
      updateBoundingBox()
    }
  }, [])

  return (
    <div className="flex flex-col items-end w-full max-w-7xl px-2 sm:px-6 lg:px-8">
      <Button
        className="mb-2"
        color="light"
        disabled={isLoadingLocation}
        onClick={useMyLocation}
      >
        {isLoadingLocation ? (
          <Spinner />
        ) : (
          <>
            <HiMapPin className="size-5 mr-1" />
            My location
          </>
        )}
      </Button>

      {geolocation ? (
        <div className="w-full">
          <MapContainer
            center={[geolocation.latitude, geolocation.longitude]}
            className="rounded-sm z-0 h-[30rem]"
            ref={handleRect}
            zoom={10}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapEvents />
            <MarkerClusterGroup
              chunkedLoading
              iconCreateFunction={createClusterCustomIcon}
              showCoverageOnHover={false}
            >
              {posts.map((post) => (
                <CircleMarker
                  center={[post.lat, post.long]}
                  color="white"
                  eventHandlers={{
                    click: () => onPreviewPost(post),
                  }}
                  fillColor={purpleColor}
                  fillOpacity={1}
                  key={post.id}
                  radius={10}
                  weight={1.5}
                />
              ))}
            </MarkerClusterGroup>
          </MapContainer>
        </div>
      ) : (
        <Spinner color="purple" size="md" />
      )}
    </div>
  )
}

export default CustomMap
