import { CheckIcon } from '@heroicons/react/16/solid'
import { useState } from 'react'
import FullscreenSpinner from 'components/FullscreenSpinner'
import SignUpForm from 'components/SignUpForm'

const SignUp = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isWaitingForEmailConfirmation, setIsWaitingForEmailConfirmation] =
    useState<boolean>(false)

  const onLoading = () => {
    setIsLoading(true)
  }

  const onSignedUp = () => {
    setIsWaitingForEmailConfirmation(true)
  }

  if (isLoading && !isWaitingForEmailConfirmation) {
    return <FullscreenSpinner />
  } else if (isWaitingForEmailConfirmation) {
    return (
      <div className="flex flex-col mt-10 items-center">
        <div className="flex flex-row items-center">
          <CheckIcon className="mr-2 size-12" />
          <h1 className="text-4xl font-bold text-center">Signed up!</h1>
        </div>

        <p className="text-center text-sm mt-10">
          Check your inbox for a confirmation email.
        </p>
      </div>
    )
  }

  return <SignUpForm onLoading={onLoading} onSignedUp={onSignedUp} />
}

export default SignUp
