import { Progress, Spinner } from 'flowbite-react'

const PostCreationProgress = ({
  isCreatingPost,
  isUploading,
  uploadPercentage,
}: {
  isCreatingPost: boolean
  isUploading: boolean
  uploadPercentage: number
}) => (
  <div className="container w-96">
    {isCreatingPost ? (
      <div className="flex flex-col items-center justify-center">
        <Spinner className="mb-2" color="purple" size="lg" />
        <p>Creating post...</p>
      </div>
    ) : isUploading ? (
      <>
        <p className="text-center mb-4">Uploading</p>
        <Progress color="purple" progress={uploadPercentage} size="lg" />
        <p className="text-center mt-2">{`${uploadPercentage}%`}</p>
      </>
    ) : null}
  </div>
)

export default PostCreationProgress
