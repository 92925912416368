import { CheckCircleIcon, NoSymbolIcon } from '@heroicons/react/16/solid'

const PostingGuidelines = () => (
  <div className="flex flex-col items-center mt-12">
    <h4 className="text-md text-center text-white sm:text-3xl">What to post</h4>

    <div className="w-fit mt-8 mx-8">
      <div className="flex items-center">
        <CheckCircleIcon className="mr-2 size-6 text-green-500" />
        <h4 className="font-semibold text-md text-center text-green-500 sm:text-3xl">
          Post photos
        </h4>
      </div>

      <ul className="ml-2.5 mt-2 max-w-md space-y-1 text-green-500 list-disc list-inside dark:text-green-400">
        <li>In public spaces where there is no expectation of privacy.</li>
        <li>You have taken yourself.</li>
      </ul>

      <div className="flex items-center mt-8">
        <NoSymbolIcon className="mr-2 size-6 text-red-500" />
        <h4 className="font-semibold text-md text-center text-red-500 sm:text-3xl">
          Photos should not
        </h4>
      </div>

      <ul className="ml-2.5 mt-2 max-w-md space-y-1 text-red-500 list-disc list-inside dark:text-red-400">
        <li>Violate reasonable expectation of privacy.</li>
        <li>Contain personal information.</li>
        <li>Feature minors.</li>
      </ul>
    </div>
  </div>
)

export default PostingGuidelines
