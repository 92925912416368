import { addThumbnailPrefix } from 'utils/addThumbnailPrefix'
import { Button } from 'flowbite-react'
import { CircleMarker, MapContainer, TileLayer } from 'react-leaflet'
import { GeoLocationNumber } from 'types/GeoLocationNumber.types'
import { HiHandThumbDown, HiHandThumbUp, HiNoSymbol } from 'react-icons/hi2'
import { POSTS_BUCKET, POSTS_TO_REVIEW_BUCKET } from 'global/variables'
import { Tables } from 'types/database.types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FullscreenSpinner from 'components/FullscreenSpinner'
import isEmpty from 'lodash/isEmpty'
import supabase from 'utils/supabase/client'

const Review = () => {
  const navigate = useNavigate()

  const [geoLocation, setGeoLocation] = useState<GeoLocationNumber | null>(null)
  const [imageURL, setImageURL] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [post, setPost] = useState<Tables<'to_review'> | null>(null)
  const [thumbnailURL, setThumbnailURL] = useState<string>('')
  const [totalNoOfPosts, setTotalNoOfPosts] = useState<number | null>(null)

  const getNextPost = async () => {
    setIsLoading(true)

    const {
      data: { user },
    } = await supabase().auth.getUser()

    if (!user) {
      console.error('No user!')
      return
    } else {
      setIsAdmin(user.app_metadata.is_admin as boolean)
    }

    if (!user.app_metadata.is_admin) {
      return
    }

    // Fetch number of posts.

    const { count } = await supabase()
      .from('to_review')
      .select('*', { count: 'exact', head: true })

    setTotalNoOfPosts(count)

    // Fetch post to review.

    const { data: post } = await supabase()
      .from('to_review')
      .select()
      .limit(1)
      .maybeSingle()

    if (!post || isEmpty(post)) {
      setIsLoading(false)

      return
    }

    setPost(post)

    // Fetch image.

    const { data: image, error: imageError } = await supabase()
      .storage.from(POSTS_TO_REVIEW_BUCKET)
      .createSignedUrl(post.filename, 60)

    if (imageError) {
      console.error('Failed to fetch image: ', imageError)
      return
    }

    setImageURL(image.signedUrl)

    // Fetch thumbnail.

    const { data: thumbnail, error: thumbnailError } = await supabase()
      .storage.from(POSTS_TO_REVIEW_BUCKET)
      .createSignedUrl(addThumbnailPrefix(post.filename), 60)

    if (thumbnailError) {
      console.error('Failed to fetch thumbnail: ', thumbnailError)
      return
    }

    setThumbnailURL(thumbnail.signedUrl)

    // Fetch location.

    const { data: postGeoLocation, error: geoLocationError } =
      await supabase().rpc('get_longitude_latitude', {
        ewkb_string: post.location as string,
      })

    if (geoLocationError) {
      console.error('Failed to fetch location: ', geoLocationError)
      return
    }

    setGeoLocation(postGeoLocation as GeoLocationNumber)

    setIsLoading(false)
  }

  useEffect(() => {
    void getNextPost()
  }, [])

  const resetReview = () => {
    setGeoLocation(null)
    setImageURL('')
    setIsAdmin(false)
    setIsLoading(true)
    setThumbnailURL('')
    setTotalNoOfPosts(null)
    setPost(null)

    void getNextPost()
  }

  const onApprove = async () => {
    if (!post) {
      console.error('No post to approve.')
      return
    }

    setIsLoading(true)

    // Move files to 'posts' bucket.

    const { error: imageCopyingError } = await supabase()
      .storage.from(POSTS_TO_REVIEW_BUCKET)
      .move(post.filename, post.filename, {
        destinationBucket: POSTS_BUCKET,
      })

    if (imageCopyingError) {
      console.error('Failed to copy image from review bucket.')
    }

    const thumbnailFilename = addThumbnailPrefix(post.filename)

    const { error: thumbnailCopyingError } = await supabase()
      .storage.from(POSTS_TO_REVIEW_BUCKET)
      .move(thumbnailFilename, thumbnailFilename, {
        destinationBucket: POSTS_BUCKET,
      })

    if (thumbnailCopyingError) {
      console.error('Failed to copy thumbnail from review bucket.')
    }

    // Add row in 'posts' table.

    const { error: addPostsRowError } = await supabase().from('posts').insert({
      filename: post.filename,
      inserted_at: post.inserted_at,
      location: post.location,
      user_id: post.user_id,
    })

    if (addPostsRowError) {
      console.error(addPostsRowError)
    }

    // Delete row in 'posts_to_review' table.

    const { error: deleteReviewRowError } = await supabase()
      .from('to_review')
      .delete()
      .eq('id', post.id)

    if (deleteReviewRowError) {
      console.error(deleteReviewRowError)
    }

    resetReview()
  }

  const onReject = async () => {
    if (!post) {
      console.error('No post to approve.')
      return
    }

    setIsLoading(true)

    // Delete row in 'posts_to_review' table.

    const { error: deleteReviewRowError } = await supabase()
      .from('to_review')
      .delete()
      .eq('id', post.id)

    if (deleteReviewRowError) {
      console.error(deleteReviewRowError)
    }

    // Delete image and thumbnail files.

    const thumbnailFilename = addThumbnailPrefix(post.filename)

    const { error: fileDeletionError } = await supabase()
      .storage.from(POSTS_TO_REVIEW_BUCKET)
      .remove([post.filename, thumbnailFilename])

    if (fileDeletionError) {
      console.error(fileDeletionError)
    }

    // Let user know.

    await supabase().functions.invoke('review_done', {
      body: {
        isApproved: false,
        userId: post.user_id,
      },
    })

    resetReview()
  }

  const onBan = () => {
    console.log('onBan()')
  }

  if (isLoading) {
    return <FullscreenSpinner />
  } else if (!isAdmin) {
    return <h1 className="text-3xl text-center mt-8">Nope!</h1>
  } else if (totalNoOfPosts === 0 || !post) {
    return (
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl text-center mt-8">Nothing to review</h1>
        <Button color="purple" className="mt-8" onClick={() => navigate('/')}>
          Home
        </Button>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center">
      <Button
        color="dark"
        className="border-none"
        onClick={() => navigate('/')}
      >
        Home
      </Button>

      <h1 className="text-3xl text-center mt-8">Review</h1>
      <p className="text-center text-md text-gray-500">
        {`${totalNoOfPosts} in total`}
      </p>

      <div className="flex flex-row justify-center m-8 space-x-4">
        {imageURL && (
          <div className="bg-neutral-900 flex justify-center w-1/2 rounded-md">
            <img
              alt="Image"
              className="object-contain rounded-md"
              src={imageURL}
            />
          </div>
        )}

        <div className="space-y-4">
          {thumbnailURL && (
            <img alt="Thumbnail" className="rounded-md" src={thumbnailURL} />
          )}

          {geoLocation && (
            <MapContainer
              center={[geoLocation.latitude, geoLocation.longitude]}
              zoom={13}
              className="rounded-md h-[30rem]"
              style={{ width: '29.3vw' }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <CircleMarker
                center={[geoLocation.latitude, geoLocation.longitude]}
                key={post.id}
                radius={10}
              />
            </MapContainer>
          )}
        </div>
      </div>

      <div className="flex size-full justify-between max-w-4xl">
        <Button color="failure" className="" onClick={onBan}>
          <HiNoSymbol className="size-5 mr-2" />
          Ban
        </Button>

        <Button color="warning" className="" onClick={onReject}>
          <HiHandThumbDown className="size-5 mr-2" />
          No
        </Button>

        <Button color="success" className="" onClick={onApprove}>
          <HiHandThumbUp className="size-5 mr-2" />
          Yes
        </Button>
      </div>
    </div>
  )
}

export default Review
