import { Button } from 'flowbite-react'
import {
  isCookieConsentGiven,
  storeCookieConsentTimestamp,
} from 'components/CookieBanner/utils'
import { useState } from 'react'

const CookieBanner = () => {
  const [cookiesJustApproved, setCookiesJustApproved] = useState<boolean>(false)

  const onOk = () => {
    storeCookieConsentTimestamp()
    setCookiesJustApproved(true)
  }

  if (isCookieConsentGiven() || cookiesJustApproved) {
    return null
  }

  return (
    <div className="fixed bottom-0 right-0 left-0 flex w-[90%] md:w-fit md:items-center flex-col mx-auto mb-20 rounded-lg border p-4 shadow-sm dark:border-gray-600 dark:bg-gray-700 md:flex-row">
      <p className="text-sm font-normal dark:text-gray-400">
        We use essential cookies necessary for the website to function and to
        analyze our traffic.
      </p>
      <Button className="mt-4 md:mt-0 md:ml-4" onClick={onOk}>
        Ok
      </Button>
    </div>
  )
}

export default CookieBanner
