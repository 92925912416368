import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'
import Contact from 'routes/Contact'
import ErrorPage from './error-page'
import Home from 'routes/Home/Home'
import NewPost from 'routes/NewPost/NewPost'
import PrivacyPolicy from 'routes/PrivacyPolicy'
import Profile from 'routes/Profile'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ResetPassword from 'routes/ResetPassword'
import Review from 'routes/Review'
import Root from 'routes/root'
import SignIn from 'routes/SignIn'
import SignUp from 'routes/SignUp'
import UpdatePassword from 'routes/UpdatePassword'
import ViewPost from 'routes/ViewPost/ViewPost'
import Welcome from 'routes/Welcome'
import WhatToPost from 'routes/WhatToPost'

import './index.css'
import 'react-toastify/dist/ReactToastify.css'

Sentry.init({
  dsn: 'https://8e0b7a6457e1f3bdab5f30a437f6e5b5@o4507847916584960.ingest.de.sentry.io/4507847920582736',
  integrations: [Sentry.browserTracingIntegration()],
  // Capture 100% of the transactions.
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'new',
        element: <NewPost />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'update-password',
        element: <UpdatePassword />,
      },
      {
        path: 'welcome',
        element: <Welcome />,
      },
      {
        path: 'post/:postId',
        element: <ViewPost />,
      },
      {
        path: 'what-to-post',
        element: <WhatToPost />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: 'review',
    element: <Review />,
  },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer />
  </React.StrictMode>
)
