import { Tooltip } from 'flowbite-react'
import round from 'lodash/round'

const VotingIndicator = ({
  noOfAgreeVotes,
  noOfDisagreeVotes,
}: {
  noOfAgreeVotes: number
  noOfDisagreeVotes: number
}) => {
  const totalVotes = noOfAgreeVotes + noOfDisagreeVotes

  const percentageAgree =
    totalVotes > 0 ? (100 * noOfAgreeVotes) / totalVotes : 50
  const percentageDisagree =
    totalVotes > 0 ? (100 * noOfDisagreeVotes) / totalVotes : 50

  const morePeopleAgree = percentageAgree > percentageDisagree

  const itIsATie = totalVotes === 0 || percentageAgree === percentageDisagree

  const toolTipMessage = `${noOfDisagreeVotes} voted No, ${noOfAgreeVotes} voted Yes.`

  return (
    <Tooltip content={toolTipMessage}>
      <p className="mb-2 text-center text-sm">{`${totalVotes} ${totalVotes === 1 ? 'Vote' : 'Votes'}`}</p>

      <div className="flex flex-row w-60 h-8 rounded-md divide-x divide-gray-400">
        <div
          className={`flex items-center justify-end bg-green-600 rounded-l-md`}
          style={{ width: `${itIsATie ? 50 : percentageDisagree}%` }}
        >
          {(itIsATie || !morePeopleAgree) && (
            <p className="text-sm mr-2">{`${round(percentageDisagree)}%`}</p>
          )}
        </div>
        <div
          className={`flex items-center bg-red-600 rounded-r-md`}
          style={{ width: `${itIsATie ? 50 : percentageAgree}%` }}
        >
          {(itIsATie || morePeopleAgree) && (
            <p className="text-sm pl-2">{`${round(percentageAgree)}%`}</p>
          )}
        </div>
      </div>
    </Tooltip>
  )
}

export default VotingIndicator
