// Local storage key where cookie consent is stored.
const consentKey = 'cookieConsentTimestamp'

/**
 * Stores in local storage the timestamp of when the user accepted the cookie consent.
 */
export const storeCookieConsentTimestamp = (): void => {
  localStorage.setItem(consentKey, new Date().toISOString())
}

/**
 * Checks if the cookie consent timestamp has been set in local storage.
 *
 * @returns A boolean indicating whether the cookie consent timestamp has been set.
 *          `true` if the timestamp exists, `false` otherwise.
 */
export const isCookieConsentGiven = (): boolean => {
  const consentTimestamp = localStorage.getItem(consentKey)

  return consentTimestamp !== null
}
