import { Button } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

const Welcome = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col mt-10 items-center">
      <h1 className="my-10 mb-6 text-4xl font-bold text-center">Welcome!</h1>
      <p className="text-center text-sm mt-10">
        Your account has been created.
      </p>

      <Button color="purple" className="mt-10" onClick={() => navigate('/')}>
        Start here
      </Button>
    </div>
  )
}

export default Welcome
